.add-note {
  margin: auto;
  width: 80%;
  text-align: center;
  /* background-color: #cbd0d5; */
}

.add-note__text-field {
  margin-bottom: 5px;
}
